<template>
  <div class="app-container">
    <el-card class="operate-container" shadow="never">
      <i class="el-icon-tickets" style="margin-top: 5px"></i>
      <span style="margin-top: 5px">Help Center</span>
    </el-card>
    <div style="margin-top: 50px">
      <div class="table-container">
        <el-table ref="ticketTable" :data="list" style="width: 100%" v-loading="listLoading" border>
          <el-table-column label="User Name" width="150" align="center">
            <template slot-scope="scope">{{ scope.row.contactName }}</template>
          </el-table-column>
          <el-table-column label="Order Status" width="150" align="center">
            <template slot-scope="scope">{{ scope.row.orderStatus }}</template>
          </el-table-column>
          <el-table-column label="Stripe" width="250" align="center">
            <template slot-scope="scope">{{ scope.row.paymentIntentId }}</template>
          </el-table-column>
          <el-table-column label="Phone" width="100" align="center">
            <template slot-scope="scope">{{ scope.row.phone }}</template>
          </el-table-column>
          <el-table-column label="Contact Name" width="200" align="center">
            <template slot-scope="scope">{{ scope.row.contactName }}</template>
          </el-table-column>
          <el-table-column label="Content" width="300" align="center">
            <template slot-scope="scope">{{ scope.row.content }}</template>
          </el-table-column>
          <el-table-column label="Send Time" width="200" align="center">
            <template slot-scope="scope">{{ scope.row.sendTime }}</template>
          </el-table-column>
          <el-table-column label="Resolve Time" width="200" align="center">
            <template slot-scope="scope">{{ scope.row.resolveTime }}</template>
          </el-table-column>
          <el-table-column label="Operations" align="center" width="200">
            <template slot-scope="scope">
              <p>
                <el-button v-if="scope.row.resolveTime == null" type="primary" size="mini" @click="resolve(scope.row)">
                  Mark as Resolved</el-button>
              </p>

              <!-- <el-button v-if="scope.row.resolveTime != null" type="danger" size="mini" @click="unresolve(scope.row)">
                Mark as Unresolved</el-button> -->
              <p>
                <el-button type="primary" size="mini" @click="openOrderDetail(scope.row)">
                  Open Order Detail
                </el-button>
              </p>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="pagination-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes,prev, pager, next,jumper"
        :page-size="listQuery.count"
        :page-sizes="[20, 50, 100]"
        :current-page.sync="listQuery.page"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { fetchList as listAll, resolve } from '@/api/orderTicket';

export default {
  name: 'TicketList',
  data() {
    return {
      list: null,
      listLoading: true,
      listQuery: {
        count: 20,
        page: 1,
      },
      total: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      const listQuery = { ...this.listQuery };
      listQuery.page -= 1;
      listAll(listQuery).then((response) => {
        this.list = response.data.data;
        this.total = response.data.recordsTotal;
        this.listLoading = false;
      });
    },
    camelToDisplay(text) {
      const result = text.replace(/([A-Z])/g, ' $1');
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;
    },
    resolve(ticket) {
      resolve(ticket.id).then(() => {
        this.getList();
      });
    },
    unresolve(ticket) {
      unresolve(ticket.id).then(() => {
        this.getList();
      });
    },
    openOrderDetail(ticket) {
      this.$router.push({
        path: '/orderService/orderDetail',
        query: { id: ticket.orderId },
      });
    },
    handleSizeChange(val) {
      this.listQuery.page = 1;
      this.listQuery.count = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
  },
};
</script>
