import request from '@/utils/request';

export function fetchList(params) {
  return request({
    url: 'v1/orderTicket/list',
    method: 'get',
    params,
  });
}

export function resolve(id) {
  return request({
    url: `/v1/orderTicket/resolve/${id}`,
    method: 'post',
  });
}
